/*/////////////////////////////////*/
/*Desktop View*/
/*/////////////////////////////////*/
/* Mobile View/Tablet View Hidden*/
#Tablet_HomePageMainSection,
#Mobile_HomePageMainSection {
    display: none;
}

#Global_HomePageMainSection {
    box-shadow: var(--box-shadow);
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100%;
}

#Global_HomePageMainSection {
    color: #818181;
}


.Global_Introduction {
    padding-inline: 10% 10%;
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: 'center';
    box-shadow: var(--box-shadow);
    margin-left: min(125px);
    margin-right: min(125px);
}

.Global_HomePageIntro {
    justify-content: center;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid;
}

.Global_HomePageIntroButtonSection {
    padding: 10px;
    width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Global_HomePageIntroButtons {
    border-radius: 8px;
    border-style: groove;
    height: 30px;
    width: 150px;
    height: 40px;
    background-color: red;
}

.Global_HomePageIntroButtonLink {
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    color: white;
}

/*/////////////////////////////////*/
/*Tablet View*/
/*/////////////////////////////////*/
@media (max-width:800px) {

    /* Mobile View/Tablet View Hidden*/
    #Global_HomePageMainSection,
    #Mobile_HomePageMainSection {
        display: none;
    }

    #Tablet_HomePageMainSection {
        display: block;
        color: #818181;
        box-shadow: var(--box-shadow);
        align-items: center;
    }


    .Tablet_Introduction {
        padding-inline: 10px 10px;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: 'center';
        box-shadow: var(--box-shadow);
        width: min(350px);
        margin: auto;
    }

    .Tablet_HomePageIntro {
        justify-content: center;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid;
        width: min(350px);
    }

    .Tablet_HomePageIntroButtonSection {
        padding: 10px;
        width: 350px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: min(350px);
    }

    .Tablet_HomePageIntroButtons {
        border-radius: 8px;
        border-style: groove;
        height: 30px;
        width: 150px;
        height: 40px;
        background-color: red;
    }

    .Tablet_HomePageIntroButtonLink {
        text-decoration: none;
        font-weight: bold;
        font-size: 18px;
        color: white;
    }
}

/*/////////////////////////////////*/
/*Mobile View*/
/*/////////////////////////////////*/
@media (max-width:390px) {

    /* Mobile View/Tablet View Hidden*/
    #Global_HomePageMainSection,
    #Tablet_HomePageMainSection {
        display: none;
    }

    #Mobile_HomePageMainSection {
        display: flex;
    }
}