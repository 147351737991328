/*/////////////////////////////////*/
/*Desktop View*/
/*/////////////////////////////////*/
#Tablet_FooterSection,
#Mobile_FooterSection {
  display: none;
}

/*/////////////////////////////////*/
/*Tablet View*/
/*/////////////////////////////////*/
@media (max-width: 800px) {
  #Global_FooterSection,
  #Mobile_FooterSection {
    display: none;
  }

  #Tablet_FooterSection {
    display: block;
  }
}

/*/////////////////////////////////*/
/*Mobile View*/
/*/////////////////////////////////*/
@media (max-width: 545px) {
  #Global_FooterSection,
  #Tablet_FooterSection {
    display: none;
  }

  #Mobile_FooterSection {
    display: block;
  }
}
