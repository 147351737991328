/*CUSTOM DESIGN*/





#NavbarSection {
    height: 8vh;
    background-color: var(--base2-color);
    box-shadow: var(--box-shadow);
}

#MainSection {
    height: 50vh;
    box-shadow: var(--box-shadow);
}

#FooterSection {
    display: flex;
    flex-direction: column;
    color: white;
    background-color: var(--base2-color);
    box-shadow: var(--box-shadow);
}