/*/////////////////////////////////*/
/*Desktop View*/
/*/////////////////////////////////*/

#Tablet_AboutPageMainSection,
#Mobile_AboutPageMainSection {
  display: none;
}
/*/////////////////////////////////*/
/*Tablet View*/
@media (max-width: 800px) {
  #Global_AboutPageSection,
  #Mobile_AboutPageSection {
    display: none;
  }
  #Tablet_AboutPageSection {
    display: block;
  }
}

/*/////////////////////////////////*/
/*/////////////////////////////////*/
/*Mobile View*/
@media (max-width: 390px) {
  #Global_AboutPageSection,
  #Tablet_AboutPageSection {
    display: none;
  }
  #Mobile_AboutPageSection {
    display: block;
  }
}
/*/////////////////////////////////*/
