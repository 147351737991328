/*CUSTOM DESIGN*/



/*ConstructionSection*/
.ConstructionPage {
    background-color: var(--base2-color);
    margin: 0;
    padding: 0;
    height: var(--full-height);
    width: var(--full-width);
    display: flex;
    flex-direction: column;
}

/*Warning Section*/
.WarningArea {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.WarningSymbol {
    font-size: 100px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.WarningCode {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.WarningNumber {
    font-size: 50px;
    font-weight: bold;
}

.WarningLabel , .WarningComment,.WarningNumber  {
    color: white;
}

/*RedirectMap Settings*/
Link {
    text-decoration: none;
}

.Atlas {
    background-color: var(--base2-color);
    margin: 0;
    padding: 0;
}

.MainPoint {
    margin: 0;
    padding: 0;
}

.SubPoint {
    margin: 0;
    padding: 0;
}

.SubPointMenu {
    display: flex;
    flex-direction: row;
    width: 200px;
}

#SubPointMenuItem {
    display: flexbox;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: auto;
    border-radius: 10px;
    border-color: var(--secondary-color);
}

#ServicesListDirectoryButton,
#PortfolioDirectoryButton,
#ContactDirectoryButton,
#CompanyDirectoryButton,
#SupportListDirectoryButton,
#ProductsListDirectoryButton,
#SocialsListDirectoryButton,
#SponsorsListDirectoryButton {
    border-radius: 10px;
    background-color: var(--base-color);
    box-shadow: var(--box-shadow);
}

#ServicesListDirectoryButton:hover ul#ServicesList,
#PortfolioDirectoryButton:hover #PortfolioList,
#ContactDirectoryButton:hover #ContactList,
#CompanyDirectoryButton:hover #CompanyList,
#SupportListDirectoryButton:hover #SupportList,
#ProductsListDirectoryButton:hover #ProductsList,
#SocialsListDirectoryButton:hover #SocialsList,
#SponsorsListDirectoryButton:hover #SponsorsList {
    display: flex;
    flex-direction: column;
    background-color: var(--base-color);
    box-shadow: var(--box-shadow);
    height: 100%;
    justify-content: flex-start;
    margin-bottom: auto;
}

#ServicesList,
#PortfolioList,
#ContactList,
#CompanyList,
#SupportList,
#ProductsList,
#SocialsList,
#SponsorsList {
    display: none;
    list-style-type: none;
}

#ServicesListSubMenuList {
    list-style-type: none;
}

/*Legend Section*/
.Legend {
    padding-inline: 1% 1%;
    writing-mode: horizontal-tb;
    margin-left: 12.5%;
    margin-right: 12.5%;
    width: auto;
    border-radius: 5px;
    border: .1px solid black;
    box-shadow: var(--box-shadow);
    color: white;

}