/*CUSTOM COLORS*/


#MainSection {
    color: #818181;
    box-shadow: #000000;
}

.Test.last {
    box-shadow: #818181;
}