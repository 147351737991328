/*/////////////////////////////////*/
/*Desktop View*/
/*/////////////////////////////////*/
.Tablet_SignUpPageMainSection,
.Mobile_SignUpPageMainSection {
  display: none;
}

.NavLinks {
  color: orange;
}

.Global_SignUpPageMainSection {
  color: white;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
}

.Sect1Val,
.Sect2Val {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  height: 25px;
  margin: 4px 0px 4px 0px;
  font-size: 1rem;
}

.DivHRZ {
  width: 100%;
  height: 2px;
  background-color: white;
}

.DivVRT {
  height: 100%;
  width: 2px;
  background-color: white;
}

#ContactSignupCard {
  display: flex;
  flex-direction: row;
  z-index: 1;
  top: 110px;
  height: 733.59px;
  width: 1332px;
  background-color: rgb(0, 0, 255, 0.98);
  border: solid white;
  border-radius: 8px;
  position: absolute;
}

.CardExit {
  appearance: none;
}

.CardExit::before {
  position: absolute;
  top: 12.5px;
  right: 25px;
  appearance: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  content: "X";
  appearance: none;
  height: 25px;
  width: 15px;
}

.Card {
  padding: 100px;
  display: flex;
  flex-direction: column;
}

.CardDescription {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#CardContactInformation {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  border-radius: 8px;
}

#ContactSignupSect1,
#ContactSignupSect2 {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#ContactSignupSect1Options,
#ContactSignupSect2Options {
  margin: 5px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Sect1Option1,
.Sect1Option2,
.Sect2Option1,
.Sect2Option2,
.Sect2Option3 {
  height: 25px;
  width: 75px;
}

.BusinessCategoryFields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  align-items: center;
}

.BusinessCategoryOption {
  border: 1px solid black;
  border-radius: 8px;
  color: blue;
  background-color: yellowgreen;
  margin: 0px 0px 0px 0px;
  height: 200px;
  width: 200px;
  padding: 0;
  word-wrap: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.BusinessCategoryOption input[type="radio" i] {
  background-color: green;
}

/*/////////////////////////////////*/
/*Tablet View*/
/*/////////////////////////////////*/
@media (max-width: "800px") {
}

/*/////////////////////////////////*/
/*Mobile View*/
/*/////////////////////////////////*/
@media (max-width: "390px") {
}
