/*CUSTOM DESIGN*/




#NavbarSection {
    height: 8vh;
    background-color: var(--base2-color);
    box-shadow: var(--box-shadow);
}

#MainSection {
    height: 58vh;
    box-shadow: var(--box-shadow);
}

#FooterSection {
    height: 32vh;
    box-shadow: var(--box-shadow);
}