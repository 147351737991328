/*/////////////////////////////////*/
/*Desktop View*/
/*/////////////////////////////////*/
/* Mobile View/Tablet View Hidden*/
#Tablet_NavbarSection,
#Mobile_NavbarSection {
  display: none;
}

/*Tablet View*/
@media (max-width: 768px) {
  /*
    Mobile View/Global View Hidden
    */
  #Global_NavbarSection,
  #Mobile_NavbarSection {
    display: none;
  }
  #Tablet_NavbarSection {
    display: block;
  }
}

/*/////////////////////////////////*/
/*Mobile View*/
@media (max-width: 390px) {
  /* 
    Mobile View/Tablet View Hidden
    */
  #Global_NavbarSection,
  #Tablet_NavbarSection {
    display: none;
  }
  #Mobile_NavbarSection {
    display: block;
  }
}
