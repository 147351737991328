/*CUSTOM COLORS*/
:root {
  /*--Colors--*/
  --base-color: #161618;
  --base2-color: #1c1c1e;
  --trim-color: #000000;
  --primary-color: #212124;
  --secondary-color: #818181;
  --test-color: rgb(2, 211, 248);
  --error-color: ;
  --pass-color: ;
  --clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  /*--Sizes--*/
  --full-height: 100vh;
  --full-width: 100vw;
  --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
#root {
  height: 100vh;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: var(--base-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
